import React from "react"

import Layout from "../../components/layout_fr"
import Seo from "../../components/seo"
import { Link, graphql } from "gatsby"
import {Container, Row, Col, Card, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline";
/*import CONFIG from '../../global-vars-trezo.js'*/
import {GatsbyImage} from "gatsby-plugin-image";
import trezoConnect from "../../images/tree.svg"



const ExpertComptable = ({data}) => (
    <Layout>


        <Seo
            titleTemplate={`Trezorino : Logiciel de gestion qui simplifie la vie des experts comptables de demain | Trezorino`}
            title="Trezorino : Logiciel de gestion simple et automatisée"
            description="Processus automatisés pour gagner du temps, réduire le risque d’erreurs et éliminer les tâches peu productives"
            image={trezoConnect}
            lang="fr"
        />

        <div className="">
            <Container>
                {data.first.edges.map(document => (
                    <Row className="home-box-header justify-content-center" key={document.node.id} >
                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                            <h1 className="title">Transformez votre cabinet comptable avec Trezorino</h1>
                            <p className="section">Prenez le virage numérique et offrez à votre cabinet comptable l'efficacité qu'il mérite.</p>
                            <p className="section">Avec Trezorino, avancez vers une ère de comptabilité simplifiée, sécurisée et moderne.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                        <Col className="home-box-image order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {/*  {section1.node.video &&

                            <video className="box-shadow" key={section1.node.video.id} src={section1.node.video.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                        }*/}

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage className="" key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-blue-inverse container-line" >
                {data.second.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Gagnez un temps précieux</h2>
                            <ul className="check">
                                <li><p className="section-h2">Partage et centralisation des informations financières de vos clients dans un espace sécurisé.</p></li>
                                <li><p className="section-h2">Saisie automatique des factures d'achats et de ventes.</p></li>
                                <li><p className="section-h2">Intégration automatique des relevés bancaires.</p></li>
                                <li><p className="section-h2">Intégration avec votre outil de comptablité.</p></li>

                            </ul>

                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/fr/demande-demo/">Demander une demo</Button>
                                </Col>
                                {/*<Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Essayer gratuitement</Button>
                                </Col>*/}
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container >
                {data.allStrapiTitleMenuFooter.edges.map(title => (
                    <Row key={title.node.id}  className="justify-content-center pb-4 pt-5">
                        <h2 className="center message-title">{title.node.Title}</h2>
                    </Row>
                ))}



                <Row xs={2} sm={2} md={2} lg={4} xl={4} className="g-4">

                    {data.allStrapiFooterMenus.edges.map(menu => (

                        <Card className="card-box" key={menu.node.id}>
                            {menu.node.MainImage.map( ImageCard=> (
                                <Card.Img  key={ImageCard.id}  variant="top" src={ImageCard.url} />
                            ))}
                            <Card.Body>
                                <Card.Title className="center card-title" >{menu.node.Title}</Card.Title>
                            </Card.Body>
                            <Card.Footer className="justify-content-center card-footer">
                                <Link to={"/fr"+menu.node.LinkHref+"/"}  className="card-link-footer">En savoir plus<IoChevronForwardOutline></IoChevronForwardOutline></Link>
                            </Card.Footer>
                        </Card>

                    ))}

                </Row>

                <Row className="justify-content-md-center pb-5 pt-5">

                </Row>

            </Container>


        </div>


    </Layout>
)

export default ExpertComptable



export const data = graphql`  
query connect_fr {
  first : allStrapiFeaturesConnects(filter: {id: {eq: "Features-connects_5"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:80, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  second : allStrapiFeaturesConnects(filter: {id: {eq: "Features-connects_3"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  trois : allStrapiFeaturesConnects(filter: {id: {eq: "Features-connects_3"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  quatre : allStrapiFeaturesConnects(filter: {id: {eq: "Features-connects_4"}}) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  all : allStrapiFeaturesConnects(
    filter: {id: {ne: "Features-connects_4"}}
    sort: {order: ASC, fields: Order}
  ) {
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
        }
        Image768 {
          id
          url
        }
        Image992 {
          id
          url
        }
      }
    }
  }
  allStrapiFooterMenus (
  sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        Title
        LinkText
        LinkHref
        Order
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiTitleMenuFooter {
    edges {
      node {
        id
        Title
      }
    }
  }
}
`
